<template>
  <div class="container my-2 p-0">
    <div class="e-top">
        <div class="bg-white p-1 text-center">
            <a @click="totalNumber">总人数({{ counts.total_number }})</a> | <a @click="thisMonth">本月({{ counts.this_month }})</a> |
            <a @click="yseDay">昨天({{ counts.yesterday }})</a> | <a @click="day">今日({{ counts.today }})</a>
        </div>

        <search></search>
    </div>  
    <div class="bg-white 1p-2 my-2 px-3" v-for="(apply,index) in data" :key="index">
      <div class="radius-5 py-1">
        <table class="border w-100 e-table">
          <tr>
            <th class="text-center" rowspan="7">
              <a href="">
                <img style="height: 65px; width: 55px" :src="apply.resume.avatar" />
                <p class="text-center m-0 mt-1">{{ apply.resume.name }}</p>
              </a>
            </th>
          </tr>

          <tr>
            <td>
              身份证:<a class="idnum">{{ apply.resume.id_num}}</a>
            </td>
          </tr>
          <tr>
            <td>性别:{{apply.resume.sex==0 ? '女' : '男'}}|民族:{{ NATION[apply.resume.nation] }}|年龄:{{ apply.resume.age }}</td>
          </tr>

          <tr>
            <td>
              Tel:<a class="calltel" :href="`tel:${apply.resume.phone}`">{{ apply.resume.phone}}</a>
            </td>
          </tr>

          <tr>
            <td>紧急联系人: {{ apply.resume.contacts }}({{ apply.resume.contacts_relation }})</td>
          </tr>
          <tr>
            <td>
              联系人tel:<a class="calltel" :href="`tel:${apply.resume.contacts_phone}`"
                >{{ apply.resume.contacts_phone }}</a
              >
            </td>
          </tr>
          <tr>
            <td>报名时间: {{ apply.created_time }}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-center py-1">
              <button class="btn btn-sm btn-outline-info mx-1 py-0" @click="followUp(apply.id)">跟进记录</button>
              <a :href="`tel:${apply.resume.phone}`" onclick="return confirm('您确定要联系他吗？？')" class="btn btn-sm btn-outline-success mx-1  py-0">联 系</a>
              <label class="btn btn-sm btn-outline-dark  py-0">状 态: {{ APPLY_STATUS[apply.status] }}</label>
              <label class="ms-3  py-0">{{ SETTLEMENT_TYPE[apply.type]}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <jmodal v-if="showModal" @close="showModal=false">
      <template v-slot:title>
          {{ mTitle }}
      </template>
      <template v-slot:content>
        <button class="btn btn-sm btn-outline-success" @click="showResume = !showResume">{{ showResume == false ? "查看简历" : '隐藏'}}</button>
        <div class="mcontent" v-if="showResume">
          <h3>姓名:{{ showApply.resume.name }}</h3>
          <p>{{ showApply.resume.description }}</p>
          <p>{{ showApply.resume.phone }}</p>
          <p>年龄: {{ showApply.resume.age }} 岁</p>
          <p>性别: {{ showApply.resume.sex == 0 ? '女' : '男' }}</p>
          <p>民族: {{ NATION[showApply.resume.nation] }}</p>
          <p>学历: {{ SCHOOLING[showApply.resume.schooling] }}</p>
          <p>工作经验: {{ JOBINDEX[showApply.resume.job_index] }}</p>
          <p>期望薪资: {{ SALARYINDEX[showApply.resume.salary_index] }}</p>
          <p>期望工作: {{ EXPECTJOB[showApply.resume.expect_job] }}</p>
          <p>工作区域: {{ showApply.resume.expect_addr }}</p>
          <p>更新时间: {{ showApply.resume.updat_time ? showApply.resume.updat_time : showApply.resume.created_time }}</p>
          <p>查看次数: {{ showApply.resume.looks }}</p>
          <p>审核状态: {{ showApply.resume.status === 0 ? '未审核' : '已审' }}</p>
        </div>
        <hr>
        <div>
          <div class="timeline">
             <span>2022-03-03</span>
             <p>测试数据</p>
          </div>
          <div class="timeline">
             <span>2022-03-08</span>
             <p>测试数据测试数据测试数据测试数据测试数据测试数据测试数据</p>
          </div>
          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="showApply.status">
            <option v-for="(v,i) in APPLY_STATUS" :key="i" :value="i">{{ v }}</option>
          </select>
          <div class="form-floating mt-2">
            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
            <label for="floatingTextarea2">备 注</label>
          </div>
          <div class="d-flex justify-content-around my-2">
              <button class="btn btn-sm btn-secondary" @click="showModal=false">取 消</button>
              <button class="btn btn-sm btn-success" @click="putApply(showApply.id)">提 交</button>
          </div>
        </div>
      </template>
  </jmodal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { SETTLEMENT_TYPE, APPLY_STATUS, NATION, SCHOOLING, SALARYINDEX, EXPECTJOB, JOBINDEX } from '@/lib/data'

const data = ref([])
const counts = ref(
  {
    total_number: 1888, //总投递人数
    this_month: 588, //本月新增
    yesterday: 68, //昨天新增
    today: 58 //今天新增
  }
)
const showModal = ref(false)
const showResume = ref(false)
const mTitle =ref()
const a = 5

for(let i=0; i<a; i++) {
    data.value.push(
      {
        id: 100 + i, //投递id
        type: 1, //结算方式
        status: 1, //状态
        created_time: '2020-03-20 8:30', //投递时间
        resume: {
          id: 200 + i, //简历id
          uid: 1000 + i, //投递者id
          name: 'jec ma', //姓名
          avatar: 'https://picsum.photos/70/70', //头像
          id_num: '320111111111111111', //身份证号码
          age: 18, //年龄
          sex: 1, // 性别
          nation: 1, //民族
          schooling: 1,
          job_index: 2,
          salary_index: 1,
          expect_job: 2,
          expect_addr: '苏州',
          phone: '138180180180', //电话
          contacts: '刘德华', //紧急联系人
          contacts_phone: '180180180190', //联系人电话
          contacts_relation: '女朋友', //联系人关系
          looks: 22,
          updat_time: '2022-03-03',
          description: 'fasfdsafsdfdsfsdafsadf',
          status: 1
        },
        job: {
          id: 100 + i,
          name: '人事主管'
        }
      }
    )
}
const showApply = ref()
function followUp(ids) {
  showModal.value = true
  data.value.forEach((val)=>{
    if(val.id == ids) {
      showApply.value = val
      mTitle.value = val.resume.name
      return
    }
  })
}
function putApply(ids) {
  if(confirm('提交成功' + ids + '是否关闭当前窗口？')) {
    showModal.value = false
  }
}
//获取统计
function getCounts() {
  // Axios.get('http://localhost:5000/').then((response)=>{
  //   if(response.data.code == 0) {
  //     // data.value = response.data.data
  //   }
  // })
}
//获取投递列表
function getData(obj) {
  console.log(obj)
  Axios.get(obj ? { path: 'http://localhost:5000/signups', query: obj } : 'http://localhost:5000/signups' ).then((response)=>{
    if(response.data.code == 0) {
      // data.value = response.data.data
    }
  })
}
//查看所有
function totalNumber() {
  getData({check: 'total_numger'})
}
//查看本月
function thisMonth() {
  getData({check: 'this_month'})
}
//查看昨天
function yseDay() {
  getData({check: 'yesterday'})
}
//查看今天
function day() {
  getData({check: 'today'})
}
onMounted(() => {
  getData()
  getCounts()
  hideTel()
})
//隐藏手机号码
function hideTel() {
  const el = document.getElementsByClassName('calltel')
  for(var i=0; i<el.length; i++) {
    el[i].text = el[i].text.substr(0,3) + '****' + el[i].text.substr(8)
  }
  const eid = document.getElementsByClassName('idnum')
  for(var b=0; b<eid.length; b++) {
    eid[b].text = eid[b].text.substr(0,4) + '****' + eid[b].text.substr(14)
  }
}
</script>

<style scoped>
.e-top {
    position: sticky;
    top: 0;
}
.e-table th {
  width: 60px;
}
.e-table tr td {
  line-height: 0.9rem;
  padding-left: 2px;
  font-size: 0.8em;
  border: 1px solid #dee2e6!important;
  padding: 2px;
}
.mcontent p {
 margin-bottom: 0;
}

.timeline {
  position: relative;
  border-left: 1px solid #E5E5E5;
  height: 40px;
  margin-left: 100px;
}
.timeline span {
  position: absolute;
  top: -17px;
  left: -90px;
  font-size: 14px;
  font-weight: 300;
  color: #999999;
  line-height: 36px;
}
.timeline p {
  position: absolute;
  left: 15px;
  top: -6px;
  font-size: 10px;
  font-weight: 400;
  color: #666666;
  line-height: 1rem;
}
.timeline::before {
  content: '';
  content: '';
  width: 13px;
  height: 13px;
  display: inline-block;
  background: #009669;
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: -5px;
}


</style>